import React from "react";
import ReactTooltip from "react-tooltip";
import { Col, Row, Dropdown } from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {
  __,
  decode,
  encode,
  getElement,
  wait,
  Event,
  isMobile,
  sendNotfication,
  forceSatoshiFormat,
  fixDate,
} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import C from "../../../../Constant";

class Deposit extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      currentCoin: "BTC",
      credits: {},
      list: [],
      content: [],
      wallet: "btc",
      deposit: true,
      slide: false,
      height: 573,
      margin: "mt-1",
      depositAddress: "Please Wait...",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    socket.emit(C.CREDIT, encode({ coin: "btc" }));
    socket.on(C.GET_ADDRESS, (data) => this.getAddress(decode(data)));
    socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));

    Event.on("deposit_archive", () => {
      this.showArchive();
    });

    wait(400).then(() => {
      this.loadAddress(true, "BTC");
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCreditAndCoins = (data) => {
    if (this._isMounted) {
      let { credit } = data;
      this.setState({ credits: credit });
      this.setUpCoins();
    }
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  getNetwork = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.network;
  };

  getCoinName = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.name;
  };

  setUpCoins = () => {
    if (isMobile()) {
      this.setState({ height: 685, margin: "mt-1" });
    }

    coins.forEach((item, i) => {
      if (item.preffix === "NC") return;

      let credit = forceSatoshiFormat(
        this.state.credits[__.lowerCase(item.preffix)]
      );

      let list = (
        <Dropdown.Item
          key={__.toString(i)}
          as={"button"}
          className={"animated fadeIn"}
          onClick={(e) => this.loadAddress(item.active, item.preffix)}
        >
          <span>
            <img
              src={"/assets/images/" + item.image}
              className={"img-fluid mini-coin mr-1"}
              alt="Coin"
            />{" "}
            {item.preffix}
          </span>
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
    });
  };

  showArchive = () => {
    if (this.state.slide) Event.emit("deposit_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(300).then(() => {
      this.setState({ deposit: !this.state.deposit });
    });

    wait(300).then(() => {
      this.loadAddress(true, "BTC");
    });
  };

  copyAddress(id) {
    var address = getElement("#" + id);
    address.select();
    address.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    sendNotfication("Copied !", "success", "top-right");
  }

  loadAddress(active, coin) {
    if (this._isMounted) {
      this.setState({ currentCoin: coin, depositAddress: "Please Wait ..." });
      if (active) socket.emit(C.GET_ADDRESS, encode({ coin: coin }));
      else {
        this.setState({ depositAddress: `${coin} not Supported yet` });
      }
    }
  }

  getAddress(data) {
    if (!data) return;
    let { address } = data;
    this.setState({ depositAddress: address });
  }

  otherCoins() {
    // show other coins modal
    Event.emit("other_coins");
  }

  render() {
    let { height, margin } = this.state;
    let style,
      size = "w-25";
    let font = "font-10";

    if (isMobile()) {
      style = "col-2";
      size = "w-50";
    }

    const tipNotice =
      "Any deposit less than the minimum amount will not be accept";

    return (
      <div className="revi">
        {this.state.deposit ? (
          <div className={this.state.slide ? "animated fadeOut " : ""}>
            <Row>
              <Col className="col-8">
                <div className="media">
                  <img
                    src={"/assets/images/" + this.state.currentCoin + ".png"}
                    className="mr-3 thumb-sm align-self-center rounded-circle"
                    alt="Coin"
                  />
                  <div clasclassNames="media-body align-self-center">
                    <div className="coin-bal">
                      <h4 className="m-0 text-white">
                        {forceSatoshiFormat(
                          this.state.credits[
                            __.lowerCase(this.state.currentCoin)
                          ]
                        )}
                      </h4>
                      <p className="text-muted mb-0">
                        {this.getCoinName(this.state.currentCoin)}
                        <span className="text-muted font-12">
                          {" "}
                          ({this.state.currentCoin})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-4 text-right">
                <Dropdown bottom="true">
                  <Dropdown.Toggle
                    split
                    align="end"
                    variant="mt-2 text-white"
                    id="dropdown-split-coins"
                  >
                    <span stle={{ fontSize: 17 }}>
                      <img
                        src={
                          "/assets/images/" + this.state.currentCoin + ".png"
                        }
                        className={"img-fluid mini-coin-12 mr-2"}
                        alt="Coin"
                      />
                      {this.state.currentCoin}
                    </span>
                    <span className="ml-1 nav-user-name">
                      <span className="caret"></span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={"droper clist-coins user-dropdown-detail"}
                  >
                    {this.state.list}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <hr className="border-mod" />
            <Row className="mb-1">
              <Col md="9" lg="9" sm="12">
                <h5 className="text-white text-left 15 my-2">
                  <ReactTooltip />
                  Deposit Address{" "}
                  <span className="text-yellow font-13">
                    ( Minimum: {this.getFee(this.state.currentCoin)} ){" "}
                    <i
                      data-tip={tipNotice}
                      className="mdi mdi-help-circle font-15 float-right"
                    />
                  </span>
                </h5>
                <div className="input-group">
                  <div className="input-group-append">
                    <button
                      onClick={(e) => this.copyAddress(this.state.currentCoin)}
                      type="button"
                      className="btn bg-cs no-shadow btn-clipboard hv"
                    >
                      <i className="far fa-copy text-white" />
                    </button>
                  </div>
                  <input
                    type="text"
                    id={this.state.currentCoin}
                    className={"form-control mspace"}
                    style={{ background: "rgb(31 40 62)", fontSize: 11 }}
                    readOnly={true}
                    value={this.state.depositAddress}
                  />
                  <div className="input-group-append">
                    <button
                      className={
                        "btn bg-cs6 no-shadow btn-clipboard hv npt " + font
                      }
                    >
                      Network: {this.getNetwork(this.state.currentCoin)}
                    </button>
                  </div>
                </div>
                {this.state.currentCoin == "BRX" && (
                  <div className="alert bg-soft-info nhv mb-0">
                    <p className="text-red">
                      Please confirm payment after deposit:{" "}
                      <a
                        href="https://forms.gle/GNhWMi7kvNGaVN8i6"
                        target="_blank"
                        onClick={() =>
                          window.open("https://forms.gle/GNhWMi7kvNGaVN8i6")
                        }
                      >
                        click here
                      </a>
                    </p>
                  </div>
                )}
                <div className="bns">
                  <div className="c1 ctss">First Deposit Bonus</div>
                  <div className="c2">100%</div>
                </div>
                <div className="bns">
                  <div className="c1 ctss">Second Deposit Bonus</div>
                  <div className="c2">50%</div>
                </div>
                <div className="bns">
                  <div className="c1 ctss">Third Deposit Bonus</div>
                  <div className="c2">25%</div>
                </div>
              </Col>
              <Col className="ycenter" md="3" lg="3" sm="12">
                <div className="text-center">
                  <img
                    src={
                      "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
                      this.state.depositAddress
                    }
                    className={"img-thumbnail qri mt-2 " + size}
                    alt="Deposit Address"
                  />
                </div>
              </Col>
            </Row>
            <div className="alert bg-soft-info nhv mb-0">
              <p className="text-white font-13 mb-0">
                <span className="text-yellow">Important: </span>
                Send only {this.state.currentCoin} to this deposit address.
                Sending any other currency to this address may result in the
                loss of your deposit. Please be careful with the minimum deposit
                amount and network.
              </p>
              <p className="text-white font-13 mb-0">
                <span className="text-yellow">Notice: </span>
                We don't need to check payments network confirmation. After
                making a deposit, you account should be charge quickly. But at
                the time of withdrawal, your deposit must have at least 1
                confirmation.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div
              className={this.state.slide == false ? "animated fadeOut" : ""}
            >
              <DepositArchive />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Deposit;
