import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Card } from "react-bootstrap";
import { connect } from "react-redux";
import Scroll from "react-scroll";
import { setName } from "../../../../actions/gameChat";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../../../Components/User/Stat/Modal";
import { getElement, __, wait, Event, sendNotfication } from "../../../../Helper";
import C from "../../../../Constant";
const Element = Scroll.Element;
const SC = Scroll.scroller;

class Messages extends React.Component {
    bubble = 'none';
    bubbleMsg = 0;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            alertSpam: false,
            messages: [],
            loader: [],
            clientCountry: storage.getKey('country') ? storage.getKey('country') : "GLOBAL",
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.setLoader();

        wait(1500).then(() => {
            socket.emit(C.CHATS, ({ country: this.state.clientCountry }));
        });

        socket.on(C.CHATS, data => this.getChats((data)));
        socket.on(C.ADD_CHAT, data => this.getNewChats((data)));

        Event.on('scrolldone', () => this.updateScroll());
        this.updateScroll();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this._isMounted) {
            if (nextProps.country) {
                this.setState({ clientCountry: nextProps.country, messages: [] });
                this.setLoader();

                wait(500).then(() => {
                    var c = nextProps.country;

                    if (__.lowerCase(c) === "spam") {
                        this.setState({ alertSpam: true });
                        c = 'BRAZIL';
                        Event.emit('open_socket');
                    }
                    else {
                        this.setState({ alertSpam: false });
                        Event.emit('close_socket');
                    }

                    socket.emit(C.CHATS, ({ country: 'global' }));
                });
            }
            this.updateScroll();
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this._isMounted) {
            let e = getElement('#messages'),
                t = e.clientHeight,
                n = e.scrollHeight,
                r = e.scrollTop;
            this.scrollBottom = n - r - t
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this._isMounted) {
            this.updateScroll();
            if (this.state.messages.length >= 150) {
                var slice = __.slice(this.state.messages, this.state.messages.length - 1, this.state.messages.length);
                this.setState({ messages: slice })
                this.state.messages.length = 150;
            }
        }
    }

    updateScroll() {
        if (this._isMounted) {
            if (this.scrollBottom <= 150) {
                SC.scrollTo('chatBarElement', {
                    delay: 3,
                    smooth: true,
                    containerId: 'messages',
                    offset: getElement('#sc').scrollHeight * 2,
                });
                getElement('.chat-bubble').classList.add('hidden');
                this.bubbleMsg = 0;
            }
            else {
                this.bubbleMsg += 1;
                if (this.bubbleMsg !== 0) {
                    getElement('.chat-bubble').classList.remove('hidden');
                }
            }
        }
    }

    setLoader = () => {
        if (this._isMounted) {
            const icon = <div className="ycenter"><div className="spinner-grow text-white" role="status" /></div>;
            this.setState({ loader: icon })
        }
    };

    getChats(data) {
        if (this._isMounted) {
            this.setState({ messages: [], loader: [] });
            sortByTime(data).forEach((message, i) => {
                this.setState(state => ({ messages: [<Message key={__.toString(i)} t={this.props.t} message={message} redux={this.props} />, ...state.messages] }));
            });
        }
    }

    getNewChats(message) {
        if (this._isMounted) {
            let { country } = this.props;
            let currentCountry = country ? country : this.state.clientCountry;

            if (__.upperCase(message.country) === __.upperCase(currentCountry)) {
                let add = this.state.messages.concat([
                    <Message t={this.props.t} key={this.state.messages.length + 1} message={message} redux={this.props} />
                ]);
                this.setState({ messages: add });
            }
        }
    }

    hideBubble = () => {
        if (this._isMounted) {
            SC.scrollTo('chatBarElement', {
                delay: 3,
                smooth: true,
                containerId: 'messages',
                offset: getElement('#sc').scrollHeight * 2,
            });
            getElement('.chat-bubble').classList.add('hidden');
            this.bubbleMsg = 0;
        }
    }

    render() {
        return (
            <Element name="chatBarElement">
                <ul className="nav chats" id={'sc'}>
                    <div onClick={this.hideBubble} className={'chat-bubble'}>
                        {this.bubbleMsg}
                    </div>
                    {this.state.loader}
                    {this.state.messages}
                </ul>
            </Element>
        );
    }
}

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: storage.getKey('name')
        }
    }

    callUser(name) {
        Event.emit('call_user', '@' + name + ' ')
    }

    createMarkup(msg) {
        return { __html: msg };
    }

    isSharing = (value) => {
        if (__.isString(value)) {
            let test = value.substr(0, 5);
            if (test === '{"gam' || test === '{"uid')
                return true;
            else
                return false;
        }
        else
            return true;
    }

    hiddenAlert = () => {
        return sendNotfication('All users in the spam room are hidden!', 'warning', 'bottom-center');
    }

    render() {
        const { t } = this.props;
        let { uid, name, message, avatar, time, level } = this.props.message;

        if (__.isUndefined(message)) return null;

        let id = uid;

        let username = name;
        let style;
        let isNotSelf = false;
        let highlight = '';

        //System Message
        if (username === 'SystemBot') {
            style = {
                background: '#673ab7 url(/assets/images/w.gif)',
                fontSize: '13px'
            }
        }

        if (this.state.name === username)
            isNotSelf = true;

        let r = new RegExp('@' + this.state.name + '(?:$|[^a-z0-9_\-])', 'i');
        if (this.state.name !== username && r.test(message)) {
            highlight += 'highlight-on';
        }

        //Check Bad Words
        var ex = /scam/gi;
        var rg = new RegExp(ex);
        if (message.match(rg)) {
            message = __.replace(message, 'scam', 'Good')
        }

        //Check Links and GIF's
        var expression = /giphy/gi;
        var reg = new RegExp(expression);
        if (!message.match(reg)) {
            var expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
            var regex = new RegExp(expression);
            if (message.match(regex)) {
                let hidden = t('link_is_hidden');
                message = <div dangerouslySetInnerHTML={this.createMarkup(`<span className="text-danger">[ ${hidden} ]</span>`)} />;
            }
        }
        else {
            message = <div dangerouslySetInnerHTML={this.createMarkup(`<img alt="GIF" class="img-fluid" src="${message}" />`)} />;
        }

        var arr = [];

        //Check Usernames Calls
        let check = __.words(message, /@([a-zA-Z0-9]+)/gi);

        if (check.length !== 0) {
            for (let i = 0; i < check.length; i++) {
                let username = check[i];
                let link = <span key={__.toString(i)} className="text-warning"> @<UserModal id={__.replace(username, '@', '')} username={__.replace(username, '@', '')} cssClass="text-warning" /></span>;
                try {
                    message = message.split(" ").map((c) => (c === username ? link : " " + c));
                } catch (e) { }
            }
        }

        return (
            <li className={'nav-item chat-part animated XfadeIn ' + highlight}>
                <UserModal id={id} t={t} level={level} cssClass={'user-avatar'} username={username} chat={true} avatar={null} />
                {(!isNotSelf) && <>
                    <div className="chat-option float-right">
                        <span className={'font-15'} onClick={() => this.callUser(username)}>
                            <span className={'list-inline-item mt-2 font-20'}>@</span>
                        </span>
                    </div>
                </>}
                <div className={'msg font-light'} style={style}>
                    <p className="m-0">{message}</p>
                </div>
                <span className={'chat-date'}>{time}</span>
            </li>
        );
    }
}

function sortByTime(input) {
    function r(c) {
        return c.sorter ? - c.sorter : null;
    }
    return __.sortBy(input, r);
}

Messages.propTypes = {
    country: PropTypes.string
};

const mapStateToProps = state => ({
    country: state.items.country
});

export default connect(mapStateToProps, { setName })(Messages);
