import React from "react";
import ReactTooltip from "react-tooltip";

export default class Social extends React.Component {
  render() {
    return (
      <>
        <span className="btn-social-icon socials">
          <ReactTooltip />
          <a
            href="https://www.facebook.com/profile.php?id=100089765182527"
            target={"_blank"}
            data-tip={"FaceBook Page"}
          >
            <i
              className={"mdi mdi-facebook-box font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://twitter.com/bitrubix_"
            target={"_blank"}
            data-tip={"Twitter News"}
          >
            <i
              className={"mdi mdi-twitter-box font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://instagram.com/bitrubix_"
            target={"_blank"}
            data-tip={"Instagram Page"}
          >
            <i
              className={"mdi mdi-instagram font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://t.me/bitrubix"
            target={"_blank"}
            data-tip={"Telegram"}
          >
            <i
              className={"mdi mdi-telegram font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
        </span>
      </>
    );
  }
}
