import React from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import storage from "../../../Storage";
import C from "../../../Constant";
import {
  getUID,
  encode,
  SITE_URL,
  decode,
  wait,
  sendNotfication,
} from "../../../Helper";

// import Terms from "./Terms";
// import List from "./List";

export default class Rakeback extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      token: storage.getKey("token") ? storage.getKey("token") : null,
      uid: getUID,
      logged: false,
      amount: 0,
    };
  }

  // componentWillMount() {
  //   document.body.scrollTop = 0;
  //   document.documentElement.scrollTop = 0;
  //   if (this.state.token !== null) {
  //     this.setState({ logged: true });
  //   }
  // }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.state.token !== null) {
      this.setState({ logged: true });
    }

    this._isMounted = true;
    wait(200).then(() => {
      socket.emit(C.RAKEBACK_AMOUNT);
    });
    socket.on(C.RAKEBACK_AMOUNT, (data) => this.addList(decode(data)));
    socket.on(C.ADD_RAKEBACK, (data) => this.makeList(decode(data)));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addList(amount) {
    if (amount) {
      this.setState({ amount: amount.amount });
    }
  }

  makeList(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        socket.emit(C.CREDIT);
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
      });
    }
  }

  addReward() {
    socket.emit(C.ADD_RAKEBACK);
  }

  render() {
    let { logged } = this.state;

    return (
      <>
        <Helmet>
          <title>Rakeback - Crypto Casino Games</title>
          <meta
            name="keywords"
            content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games"
          />
          <meta
            name="description"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <meta
            name="og:title"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <meta
            name="og:description"
            content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !"
          />
          <link rel="canonical" href="/rakeback" />
        </Helmet>
        <div>
          <div
            style={{
              background: "#161c2c url(/assets/images/mountain.png)",
              backgroundSize: "cover",
            }}
            className={"p-4 wheel-content"}
          >
            <h2
              className={"text-center text-upper text-warning font-weight-bold"}
            >
              Rakeback
            </h2>

            <Row>
              <Col sm={12} xl={5} md={5} className={"m-auto"}>
                <Card>
                  <Card.Body
                    className={"rounded text-center"}
                    style={{ background: "#243042" }}
                  >
                    <label className="text-white text-upper">
                      Current Rakeback reward
                    </label>
                    {logged ? (
                      <>
                        <input
                          type="text"
                          value={this.state.amount}
                          className={"form-control text-center"}
                        />
                        <button
                          className="mt-2 btn btn-more my-3 px-4 shadow-none btn-sm animated fadeIn text-center"
                          onClick={(e) => this.addReward()}
                        >
                          Claim
                        </button>
                      </>
                    ) : (
                      <input
                        type="text"
                        value={"Please login to see your Reward."}
                        className={"form-control"}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
