import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  __,
  isMobile,
  wait,
  CRISTP_ID,
  addNewStyle,
  SITE_URL,
  decode,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      games: [],
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this.state.games.length === 0) socket.emit(C.GAMES);

    wait(500).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);

    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (isMobile()) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }

  render() {
    return (
      <span>
        {this.state.show && (
          <ul className="list-unstyled topbar-nav mb-0 menu-items">
            <MobileDrawer games={this.state.games} t={this.props.t} />
          </ul>
        )}
      </span>
    );
  }
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    color: "#FFF",
  },
  logo: {
    margin: 0,
    padding: "10px 12px 0",
    borderBottom: "1px solid #1c1f2d",
  },
  logoImage: {
    verticalAlign: "top",
  },
  links: {
    marginTop: 5,
    padding: "10px 12px",
    display: "block",
  },
  link: {
    fontSize: "14px !important",
  },
  icon: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    background: "#393d51",
    padding: 6,
  },
});

function MobileDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const fixer = (game) => {
    if (game === "heads-or-tails") return "heads";
    if (game === "hash-dice") return "hash dice";
    if (game === "classic-dice") return "classic dice";
    if (game === "rps") return "plinko";
    return __.replace(game, "_", " ");
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.drawer]: "left",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {props.games.map(
          (item, index) =>
            !item.disabled && (
              <Link key={__.toString(index)} to={"/" + item.prefix}>
                <ListItem button key={item}>
                  <ListItemIcon>
                    <img
                      className={clsx(classes.icon) + " mr-1"}
                      src={
                        "/assets/images/icons/" + fixer(item.prefix) + ".png"
                      }
                      alt={item.prefix}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={clsx(classes.link)}
                    primary={item.name}
                  />
                </ListItem>
              </Link>
            )
        )}
      </List>
    </div>
  );

  const anchor = "left";
  const { t } = props;

  let loaded = false;

  const support = () => {
    var d, s;
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      loaded = true;
      (function () {
        d = document;
        s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.id = "chtt";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      var close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  return (
    <>
      <React.Fragment key={anchor}>
        <li className={"nav-link ml-0"} onClick={toggleDrawer(anchor, true)}>
          <i className={"mdi mdi-menu text-white font-20"} />
        </li>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <ul className={clsx(classes.logo)}>
            <li>
              <Link to="/" className="logo">
                <span>
                  {/* <img
                    src="/assets/images/lg.png"
                    alt="logo-small"
                    className={"logo-50 " + classes.logoImage}
                  /> */}
                  <b className="text-white font-20 d-inline-block text-uppercase">
                    {SITE_URL}
                  </b>
                </span>
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/slots"
                className={clsx(classes.links)}
                onClick={toggleDrawer(anchor, false)}
              >
                <span>{t("slots")}</span>
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/about-us"
                className={clsx(classes.links)}
                onClick={toggleDrawer(anchor, false)}
              >
                <span>About Us</span>
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                to="/rakeback"
                className={clsx(classes.links)}
                onClick={toggleDrawer(anchor, false)}
              >
                <span>{t("Rakeback")}</span>
              </Link>
            </li>
            <li className="d-inline-block">
              <Link
                onClick={() => support()}
                to="#"
                className={clsx(classes.links)}
              >
                <span>{t("support")}</span>
              </Link>
            </li>
          </ul>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default MobileMenu;
