import React, { Component } from 'react'
import { Row, Col, Card } from "react-bootstrap";
import { __, forceSatoshiFormat } from "../../../../Helper";
import storage from "../../../../Storage";

class Table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
			scoreName: null
		}
	}

	componentDidMount() {
		this.props.engine.on('update_table', (score,) => {
			this.setState({ scoreName: __.upperCase(score) })
		})

		this.props.engine.on('update_table_amount', (amount) => {
			this.setState({ amount: parseFloat(amount) })
		})
	}

	render() {
		const { scoreName } = this.state;
		return (
			<>
				<Row className="total-payment videopoker-table mb-2">
					<Col md={6} lg={6} sm={12} className={'col-12'}>
						<Row>
							<Col className={(scoreName === 'ROYAL FLUSH') ? ' active col-6 ' : ' col-6 text-left'}>
								ROYAL FLUSH
							</Col>
							<Col className={(scoreName === 'ROYAL FLUSH') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">800x - {forceSatoshiFormat(this.state.amount * 800)}</span>
							</Col>
							<Col className={(scoreName === 'STRAIGHT FLUSH') ? ' active col-6 ' : ' col-6 text-left'}>
								STRAIGHT FLUSH
							</Col>
							<Col className={(scoreName === 'STRAIGHT FLUSH') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">90x - {forceSatoshiFormat(this.state.amount * 90)}</span>
							</Col>
							<Col className={(scoreName === 'FOUR OF A KIND') ? ' active col-6 ' : ' col-6 text-left'}>
								4 OF A KIND
							</Col>
							<Col className={(scoreName === 'FOUR OF A KIND') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">25x - {forceSatoshiFormat(this.state.amount * 25)}</span>
							</Col>
							<Col className={(scoreName === 'FULL HOUSE') ? ' active col-6 ' : ' col-6 text-left'}>
								FULL HOUSE
							</Col>
							<Col className={(scoreName === 'FULL HOUSE') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">9x - {forceSatoshiFormat(this.state.amount * 9)}</span>
							</Col>
							<Col className={(scoreName === 'FLUSH') ? ' active col-6 ' : ' col-6 text-left'}>
								FLUSH
							</Col>
							<Col className={(scoreName === 'FLUSH') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">6x - {forceSatoshiFormat(this.state.amount * 6)}</span>
							</Col>
						</Row>
					</Col>
					<Col md={6} lg={6} sm={12} className={'col-12'}>
						<Row>
							<Col className={(scoreName === 'STRAIGHT') ? ' active col-6 ' : ' col-6 text-left'}>
								STRAIGHT
							</Col>
							<Col className={(scoreName === 'STRAIGHT') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">4x - {forceSatoshiFormat(this.state.amount * 4)}</span>
							</Col>
							<Col className={(scoreName === 'THREE OF A KIND') ? ' active col-6 ' : ' col-6 text-left'}>
								3 OF A KIND
							</Col>
							<Col className={(scoreName === 'THREE OF A KIND') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">3x - {forceSatoshiFormat(this.state.amount * 3)}</span>
							</Col>
							<Col className={(scoreName === 'TWO PAIR') ? ' active col-6 ' : ' col-6 text-left'}>
								2 PAIRS
							</Col>
							<Col className={(scoreName === 'TWO PAIR') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">2x - {forceSatoshiFormat(this.state.amount * 2)}</span>
							</Col>
							<Col className={(scoreName === 'JACKS OR BETTER') ? ' active col-6 ' : ' col-6 text-left'}>
								JACKS OR BETTER
							</Col>
							<Col className={(scoreName === 'JACKS OR BETTER') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">1x - {forceSatoshiFormat(this.state.amount * 2)}</span>
							</Col>
							<Col className={(scoreName === 'NOTHING') ? ' active col-6 ' : ' col-6 text-left'}>
								NOTHING
							</Col>
							<Col className={(scoreName === 'NOTHING') ? ' active col-6 ' : ' col-6 text-right'}>
								<span className="text-warning">0x - {forceSatoshiFormat(0)}</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	}

}

export default Table
