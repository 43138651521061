import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Wallet from "../../../Components/User/Wallet";
import History from "../../../Components/User/History";
import UserModal from "../../../Components/User/Stat/Modal";
import storage from "../../../../Storage";
import { __, defaultAvatar, getUID } from "../../../../Helper";
import PrivateMessage from "./../PrivateMessage";
import Logout from "./../../Auth/Logout";

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            level: 1,
            uid: getUID,
            name: storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest',
            avatar:  defaultAvatar
        };
    }
    render() {
        const { t } = this.props;
        let { uid, level, name, avatar } = this.state;

        if (__.isNaN(uid))
            uid = name;

        return (
            <li className={"user-dropdown"}>
                <Dropdown left="true">
                    <Dropdown.Toggle split variant="b btn-xs mt-1" id="dropdown-split-user">
                        <img src={this.state.avatar} alt="profile-user" className="thumb-md rounded-circle" />
                        <span className="ml-1 nav-user-name hidden-sm">
                            <span className="caret"></span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={"droper user-dropdown-detail"}>
                        <UserInfo id={uid} level={level} name={name} avatar={avatar} t={t} />
                        <div className="dropdown-divider" />
                        <div className="user-links">
                            <Dropdown.Item className={"animated fadeIn"}>
                                <Wallet t={t} />
                            </Dropdown.Item>
                            <Dropdown.Item className={"animated fadeIn py-0 px-1-"}>
                                <History t={t} />
                            </Dropdown.Item>
                            <Dropdown.Item as={'button'} className={"animated fadeIn"}>
                                <Link to={'/setting'} className={"dropdown-item text-white"}>
                                    <i className="dripicons-gear text-muted mr-2 text-drop" />
                                    {t('setting')}
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item as={'button'} className={"animated fadeIn"}>
                                <Logout t={t} />
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
        );
    }
}

class UserInfo extends React.Component {
    makeStars = (num) => {
        let stars = 5;
        let userLevel = stars - parseFloat(num);
        var g = [], o = [], f = [];
        for (var i = 0; i < userLevel; i++) {
            g.push(<span className="graysi">🏆</span>);
        }
        for (var i = 0; i < num; i++) {
            o.push(<span>🏆</span>);
        }
        f.push(o, g);
        return f;
    }
    render() {
        const { name, id, level, avatar, t } = this.props;
        return (
            <>
                <div className="user-bar">
                    {<UserModal t={t} username={name} id={id} menu={true} avatar={'/assets/images/avatar.png'} />}
                    <span className="text-white">{name}</span>
                    <br />
                    <span>{this.makeStars(level)}</span>
                </div>
            </>
        );
    }
}

export default User;
