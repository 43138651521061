import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Helmet } from 'react-helmet'
import Popular from "./Popular";
import Games from "./Games";
import Bets from "./Bets";
// import Slider from "./Slider";
import Winners from "../../../Winners";
import Slots from "../../../Slots/Slots";
import { BRAND, isMobile } from "../../../../Helper";

class Home extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            padding: 'py-2',
        }
    }

    componentWillMount() {
        this._isMounted = true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (isMobile()) {
            this.setState({ padding: 'p-1' })
        }
    }

    render() {
        let { content, t } = this.props;
        return (
            <>
                {this._isMounted &&
                    <>
                        <Helmet>
                            <title>{BRAND} - Crypto Casino Games and Crypto Gambling</title>
                            <meta name="keywords" content="Crypto Casino Games, Online Casino Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, PlayCrash.com Crypto Games" />
                            <meta name="description"
                                content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
                            <meta name="og:title" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
                            <meta name="og:description" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
                        </Helmet>

                        {/* Page Content */}
                        {content}

                        <div className="mt-1">

                            <Card className="brd7">
                                <Card.Body className={this.state.padding}>
                                    <h4 className="mt-2 mb-2 section-title"> Most Popular</h4>
                                    <Popular t={t} />
                                </Card.Body>
                            </Card>

                            <Card className="brd7">
                                <Card.Body className={this.state.padding + ' pb-3'}>
                                    <h4 className="mt-2 mb-2 section-title"> {t('originals')} Games</h4>
                                    <Games t={t} />
                                </Card.Body>
                            </Card>

                            <Card className="brd7">
                                <Card.Body className={this.state.padding}>
                                    <h4 className="mt-2 mb-2 section-title"> Slots</h4>
                                    <Slots home={true} />
                                    <div className="text-center">
                                        <Link className="btn btn-more btn-sm px-4 mt-1 mb-3" to="/slots">
                                            <i className="mdi mdi-arrow-right-bold-box align-middle" /> Show More
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="mt-0 mb-3">
                            <Card className="brd7">
                                <Card.Body className={this.state.padding + ' pb-3'}>
                                    <h4 className={'my-3 section-title'}>
                                        Last Bets
                                    </h4>
                                    <Bets t={t} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="mt-4">
                            <Card className="brd7">
                                <Card.Body className={this.state.padding + ' pb-3'}>
                                    <h4 className={'my-3 section-title'}>
                                        Top Winners
                                    </h4>
                                    <Winners t={t} />
                                </Card.Body>
                            </Card>
                        </div>

                    </>
                }
            </>
        );
    }
}

export default Home;