import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Faq - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games" />
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/faq" />
                </Helmet>
                <div>
                    <Card>
                        <Card.Body>
                            <p className="about-us-page">
                                <h2 className="text-secondary">Faq</h2>

                                

                            </p>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
