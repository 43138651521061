import React from 'react';
import { Card, Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";
import { isMobile, wait, Event } from "../../../Helper";
import Loading from "../Loading";

class Index extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            margin: 'mt-3',
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);

        wait(300).then(() => {
            this._Mounted = false;
        });

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({ padding: 'p-0', margin: 'mt-1', ovh: 'ovh' });
        }
    }
    render() {
        let { col, padding, ovh } = this.state;

        const help = <p className={'font-light text-white font-15'}>
            At the High or Low game, the result should be higher or lower than 500.
            and if you choose equal, the result must be equal to one of the round numbers. 111, 222, etc...
        </p>

        return (
            <>
                <Helmet>
                    <title>High or Low - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto High or Low Game, Online High or Low Game, Bitcoin High or Low Game, Blockchain High or Low Game, Best Crypto High or Low Game, PlayCrash.com High or Low" />
                    <meta name="description" content={"High or Low is a game of betting below or under the number 50000 by the payout. If you choose “low” that means the number must come in under 49999 and higher than your payout. If you choose “High” that means  number must come in 50000 or over than your payout."} />
                    <meta name="og:title" content="Play Online High or Low - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online High or Low - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/high-or-low" />
                </Helmet>
                <div >
                    {this._Mounted ?
                        <Row className={"animated fadeIn " + ovh}>
                            <div className="d-none">Crypto High or Low Game Description: {help}</div>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                {/* BANKROLL */}
                                <Card className="mb-0">
                                    <Card.Body className="px-2 py-3">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'high_low'} />
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <TopBarGames help={help} image="/assets/images/hog.png" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'bg-dice-options hash_diceBG p-1'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                <Game engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* BET */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={10} className={'m-auto'}>
                                                <Bet engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* QUEUE */}
                                <Card className="mb-5">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto px-1'}>
                                                <Queue t={this.props.t} game={'high_low'} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;
