import { Link } from "react-router-dom";
import React from "react";
import { BRAND } from "../../../../Helper";

class Logo extends React.Component {
  render() {
    return (
      <>
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span>
              <img src="/assets/images/lg.png" className="logo-sm" alt="Logo" />
              {/* <span className={"ml-1 font-25 text-logo"}>
                                    <span>{BRAND}</span>
                                    <h1 className="d-none">Crypto Casino</h1>
                                </span> */}
            </span>
          </Link>
        </div>
      </>
    );
  }
}

export default Logo;
