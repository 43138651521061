import React from "react";
import { toUSD, Game, wait } from "../../../../Helper";

export default class Bitcoin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bitcoin: null
        };
    }

    componentDidMount() {
        wait(1000).then(() => {
            this.setState({ bitcoin: toUSD(Game['BTC']) })
        })
    }

    render() {
        return (
            <>
                <p className="text-center bitcoin-price">1 BTC : {this.state.bitcoin}</p>
            </>
        );
    }
}
