import React, { Component } from "react";
import { Button, Row, Col, Dropdown } from "react-bootstrap";
import axios from "axios";
import socket from "../../../../Socket";
import {
  __,
  decode,
  encode,
  isValidNumber,
  forceSatoshiFormat,
  wait,
  sendNotfication,
  Event,
} from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins";
import WithdrawlArchive from "./WithdrawlArchive";
import C from "../../../../Constant";

//_isMounted can prevent from double socket response

const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);

class Withdrawl extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      credit: storage.getKey("credit"),
      showArchive: false,
      slide: false,
      final: false,
      amount: 0,
      amount1: 0,
      im: true,
      error: false,
      error2: false,
      loading: false,
      loader: false,
      list: [],
      list1: [],
      coin: "BTC",
      coin1: "USDT",
      fee_withdrawal: FEE_WITHDRAWL,
      price: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMaxBits = this.setMaxBits.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
    socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
    socket.on(C.SUBMIT_NEW_SWAP, (data) => this.response(decode(data)));

    coins.forEach((item, key) => {
      if (item.preffix === "NC") return;

      let list = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );

      let list1 = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin1(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
      this.setState((state) => ({ list1: [list1, ...state.list1] }));
    });

    Event.on("withdraw_archive", () => {
      this.showArchive();
    });

    this.callPrice();
    this.exchangeCoin(this.state.amount, 1);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  callPrice() {
    const api = "https://api.bitrubix.games/rate";
    axios({
      url: api,
      method: "GET",
    }).then(
      function (req) {
        const res = req.data;
        console.log(res);
        this.setState({ price: res });
      }.bind(this)
    );
  }

  exchangeCoin(amount, id) {
    let { coin, coin1, price } = this.state;
    if (!price) {
      return;
    }
    const coins = price;

    const fromPrice = coins[coin.toUpperCase()];

    const toPrice = coins[coin1.toUpperCase()];

    const exchangeRate = fromPrice / toPrice;

    const exchangeRate1 = toPrice / fromPrice;

    // Convert the amount of the 'fromCoin' to the 'toCoin'
    const exchangedAmount = amount * exchangeRate;

    const exchangedAmount1 = amount * exchangeRate1;

    // this.setState({ amount1: exchangeCoin(coin, coin1, amount) });
    if (id == 1) {
      this.setState((prevState) => ({
        amount1: exchangedAmount,
      }));
    } else {
      this.setState((prevState) => ({
        amount: exchangedAmount1,
      }));
    }

    // return exchangedAmount;
  }

  response(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
        socket.emit(C.CREDIT);
        this.setState({
          final: false,
          showArchive: false,
          loading: false,
        });
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name === "coin") {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    }

    if (target.name === "amount") {
      if (!isValidNumber(value)) return false;

      if (value.length > 20) {
        return false;
      }
    }

    if (target.name === "amount") {
      let error = false;

      if (Number(value) > Number(this.maxUserCredit())) error = true;

      this.setState({ error: error });
      this.exchangeCoin(value, 1);
    }

    if (target.name === "amount1") {
      this.exchangeCoin(value, 2);
    }

    this.setState({ [target.name]: value, error3: false });

    console.log("price", this.state.price);
  }

  getUserCredit() {
    return forceSatoshiFormat(this.state.credit);
  }

  setCreditCoin(data) {
    let { credit } = data;
    this.setState({ credit: credit, amount: credit });
  }

  maxUserCredit() {
    this.setState({ error: false, error2: false });
    return this.getUserCredit();
  }

  setMaxBits() {
    this.setState({ amount: this.maxUserCredit() });
  }

  submitForm(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { error, coin, amount, coin1, amount1, credit, fee_withdrawal } =
        this.state;

      if (error === true) return;

      if (!Number(amount) && !amount) return;

      if (amount <= forceSatoshiFormat(0.0)) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      var check = amount - fee_withdrawal;

      if (check <= 0.0) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

      if (__.toNumber(finalMoney) <= 0) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      let restrecFee = true,
        minFee;

      //Check for minimum amount
      coins.forEach((item, key) => {
        if (__.lowerCase(item.preffix) === __.lowerCase(coin)) {
          let min = forceSatoshiFormat(item.min);
          finalMoney = __.toNumber(finalMoney);
          min = __.toNumber(min);
          if (finalMoney < min) {
            minFee = forceSatoshiFormat(min);
            restrecFee = false;
          }
        }
      });

      if (!restrecFee) {
        this.setState({ error2: true, minFee: minFee });
        return;
      }

      this.setState({ loading: true });

      wait(500).then(() => {
        socket.emit(
          C.SUBMIT_NEW_SWAP,
          encode({
            amount: amount,
            amount1: amount1,
            coin: coin,
            coin1: coin1,
            immed: fee_withdrawal,
          })
        );
      });
    }
  }

  onChange(event, list) {
    this.setState({ list: list });
  }

  showArchive = (e) => {
    if (this.state.slide) Event.emit("withdraw_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(500).then(() => {
      this.setState({ showArchive: !this.state.showArchive });
    });
  };

  handleInputCoin = (active, value) => {
    if (active) {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount: forceSatoshiFormat(0) });
    }
    this.setState({
      coin: value,
      error: false,
      error2: false,
      amount: 0,
      amount1: 0,
    });
  };

  handleInputCoin1 = (active, value) => {
    if (active) {
      // socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount1: forceSatoshiFormat(0) });
    }
    this.setState({
      coin1: value,
      error: false,
      error2: false,
      amount: 0,
      amount1: 0,
    });
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  render() {
    let UNIT = this.state.coin;
    let UNIT1 = this.state.coin1;
    let {
      loader,
      final,
      error,
      showArchive,
      amount,
      credit,
      error2,
      minFee,
      fee_withdrawal,
      error3,
      amount1,
      coin1,
    } = this.state;
    let details = withdrawlDetail(
      amount,
      fee_withdrawal,
      UNIT,
      credit,
      error,
      amount1,
      coin1
    );
    const top = true;
    return (
      <>
        <div className={this.state.slide ? "animated fadeOut mt-1" : " mt-1"}>
          <>
            <div className={"m-auto wallet"}>
              <form
                className="w-100"
                onSubmit={(e) => {
                  this.submitForm(e);
                }}
              >
                {loader ? (
                  <></>
                ) : (
                  <Row
                    className={
                      this.state.final === false
                        ? "animated fadeIn text-center"
                        : "text-center"
                    }
                  >
                    <Col md="6" lg="12" sm="8">
                      <div className="form-group mb-3">
                        <label htmlFor="targetWallet" className="text-white">
                          from
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            value={this.state.amount}
                            className="form-control bgl2"
                            name="amount"
                            id="targetAmount"
                            placeholder="0"
                            onChange={this.handleInputChange}
                            required={true}
                            autoComplete={"off"}
                          />
                          <span className="input-group-append">
                            <div className="input-group fw">
                              <Dropdown
                                top={top.toString()}
                                className={
                                  "drop-select clist3 btn-block bgl2 py-0 pt-2"
                                }
                              >
                                <Dropdown.Toggle
                                  split
                                  variant=""
                                  className={"py-0 mt-0 bgl2"}
                                >
                                  <span className="text-muted text-center font-12">
                                    <span className="caret mr-1"></span>
                                    <img
                                      src={
                                        "/assets/images/" +
                                        __.upperCase(this.state.coin) +
                                        ".png"
                                      }
                                      className={"mini-coin-9"}
                                      alt="COIN"
                                    />
                                    {this.state.coin}
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={"dopdown-menu-bottom"}
                                  align={"left"}
                                >
                                  {this.state.list}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </span>
                          {this.state.coin == this.state.coin1 && (
                            <ul className="mt-2 d-inline-block w-100 p-0">
                              <li className="text-yellow font-12">
                                Invalid Pair
                              </li>
                            </ul>
                          )}
                          {error && (
                            <ul className="mt-2 d-inline-block w-100 p-0 m-0">
                              <li className="text-yellow font-12">
                                Balance not enough
                              </li>
                            </ul>
                          )}
                          {error3 ? (
                            <ul className="mt-2 d-inline-block w-100 p-0 m-0">
                              <li className="text-yellow font-12">{error3}</li>
                            </ul>
                          ) : (
                            <ul className="mt-2 d-inline-block w-100 p-0">
                              <li className="text-yellow font-12">
                                Minimum : {this.getFee(this.state.coin)} {UNIT}
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6" lg="12" sm="8">
                      <div className="form-group mb-3">
                        <label htmlFor="targetWallet" className="text-white">
                          to
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            value={this.state.amount1}
                            className="form-control bgl2"
                            name="amount1"
                            id="targetAmount"
                            placeholder="0"
                            onChange={this.handleInputChange}
                            required={true}
                            autoComplete={"off"}
                          />
                          <span className="input-group-append">
                            <div className="input-group fw">
                              <Dropdown
                                top={top.toString()}
                                className={
                                  "drop-select clist3 btn-block bgl2 py-0 pt-2"
                                }
                              >
                                <Dropdown.Toggle
                                  split
                                  variant=""
                                  className={"py-0 mt-0 bgl2"}
                                >
                                  <span className="text-muted text-center font-12">
                                    <span className="caret mr-1"></span>
                                    <img
                                      src={
                                        "/assets/images/" +
                                        __.upperCase(this.state.coin1) +
                                        ".png"
                                      }
                                      className={"mini-coin-9"}
                                      alt="COIN"
                                    />
                                    {this.state.coin1}
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={"dopdown-menu-bottom"}
                                  align={"left"}
                                >
                                  {this.state.list1}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="5" lg="5" sm="12">
                      <label
                        htmlFor="targetAmount"
                        className="text-white"
                      >
                        Enter Amount ({UNIT})
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={this.state.amount}
                          className="form-control bgl2"
                          name="amount"
                          id="targetAmount"
                          placeholder="0"
                          onChange={this.handleInputChange}
                          required={true}
                          autoComplete={"off"}
                        />
                        <div className="input-group-append">
                          <Button
                            variant="btn bg-greys no-shadow btn-sm btn-clipboard"
                            onClick={this.setMaxBits}
                          >
                            <i className="mdi mdi-coins mr-1" /> Max
                          </Button>
                        </div>
                      </div>
                    </Col> */}
                    <Col sm="12">
                      <div className="form-group text-center">
                        <button
                          onClick={(e) => this.submitForm(e)}
                          className="btn btn-s-4 btn-sm px-4"
                          type="submit"
                          disabled={this.state.loading}
                        >
                          <i className="far fas fa-dot-circle mr-1" /> Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </form>
              <div className="form-group mt-2 mb-0">
                {loader ? (
                  <div className="ycenter text-center"></div>
                ) : (
                  <div className="withdrawl-detail p-1 bg-soft-dark text-white">
                    {details}
                  </div>
                )}
                {/* {loader ? (
                  <></>
                ) : (
                  <p className="mt-1 mb-0 p-2 bg-soft-dark text-white">
                    Your withdrawal will be sent from the hot wallet, do
                    not withdraw to any site that uses the sending
                    address, or returns to sender, because any returns
                    will probably be credited to a different player.
                  </p>
                )} */}
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}

function withdrawlDetail(amount, fee, coin, credit, error, amount1, coin1) {
  let total = forceSatoshiFormat(amount - fee);

  return (
    <>
      <ul className={"p-2 m-0"}>
        <li>
          Will Receive Approximately:{" "}
          <span className="text-yellow">
            {amount1} {coin1}
          </span>
        </li>
        <li>
          Balance available for Swap: <b>{forceSatoshiFormat(credit)}</b> {coin}
        </li>
        <li>
          Amount to Swap: <b>{amount}</b> {coin}
        </li>
        <li>
          Swap Fee: <b>{forceSatoshiFormat(fee)}</b> {coin}
        </li>
        <li>
          Total: <span className="text-yellow">{total}</span>
        </li>
      </ul>
    </>
  );
}

export default Withdrawl;
