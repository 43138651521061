import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import { Table } from "react-bootstrap";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import socket from "../../../Socket";
import storage from "../../../Storage";
import UserModal from "../../Components/User/Stat/Modal";
import {__, fixDate, Event, isMobile, decode, encode, forceSatoshiFormat, timeConvertor} from "../../../Helper";
import C from "../../../Constant";
import {userUID} from "../../../Auth";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));


function Queue(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let { engine, game } = props;
    let isLogged = storage.getKey('logged');

    return(
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'mdi mdi-chart-bar'} />} label="All Bets" {...a11yProps(0)} />
                        <Tab icon={<i className={'mdi mdi-chart-areaspline'} />} label="My Bets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x' === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={'x'}>
                        <AllBets engine={engine} t={props.t} game={game} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={'x'}>
                        { (isLogged !== null) &&
                            <MyBets t={props.t} game={game} />
                        }
                        { (isLogged === null) &&
                            <div className={'alert bg-soft-purple mt-2 font-13 text-white text-center'}>You must be logged to see your stats</div>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
    );
}

class AllBets extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            players: [],
            winners: [],
            failed:  []
        };
    }

    componentDidMount() {
        this.props.engine.getStatus();
    }

    onChange(event, players, winner) {
        this.setState({ players: players, isWinner: winner } )
    }

    onWinner(event, winners) {
        this.setState({ winners: winners } )
    }

    render () {
        const { players, winners } = this.state;
        return <Child engine={this.props.engine} onChange={this.onChange.bind(this, players)} onWinner={this.onWinner.bind(this, winners)} />
    }
}

class Child extends React.Component {
    _isMounted = false;
    constructor (props) {
        super(props);
        this.state = {
            playersObject: [],
            winnersObject: []
        };
    }

    componentDidMount(){
        let { engine } = this.props;
        this._isMounted = true;
        engine.on(C.PLAY_KENO, data => this.syncPlayPlayer(data));
        engine.on(C.BUSTED_KENO, (data) => this.emptyQueue(data));
        engine.on(C.WAITING_KENO, (data) => this.isWaiting(data));
        engine.on(C.STATUS_KENO, data => this.gameSync(data));
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    syncPlayPlayer(data){
        if (this._isMounted) {
            this.state.playersObject.push(data);
            this.props.onChange(this.state.playersObject)
        }
    }

    gameSync(data){
        if (this._isMounted) {
            data.players.forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);

            data.winners.forEach((item, i) => {
                this.state.winnersObject.push(item);
            });
            this.props.onWinner(this.state.winnersObject,true);
        }
    }

    emptyQueue(data){
        if (this._isMounted) {
            this.setState({ playersObject: [], winnersObject: []} );
            this.state.playersObject.shift();
            this.state.winnersObject.shift();

            data.players.forEach((item, i) => {
                this.state.playersObject.push(item);
            });

            this.props.onChange(this.state.playersObject);

            data.winners.forEach((item, i) => {
                this.state.winnersObject.push(item);
            });

            this.props.onWinner(this.state.winnersObject,true);
        }
    }

    isWaiting(data){
        this.setState({ playersObject: [], winnersObject: [] } );
        data.players.forEach((item, i) => {
            this.state.playersObject.push(item);
        });
        this.props.onChange(this.state.playersObject);
    }

    render () {
        return(
            <ShowUserTable
                engine={this.props.engine}
                players={this.state.playersObject}
                winners={this.state.winnersObject}
            />
        )
    }
}

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if(isMobile()){
            this.setState({ font: 'font-10' })
        }
    }

    render() {
        let playerRows = [];
        let winnerRows = [];
        let checkExists = [];

        if(this.props.players.length > 0) {
            this.props.players.forEach((currentPlayer, i) => {
                if(!checkExists.includes(currentPlayer.name)){
                    checkExists.push(currentPlayer.name);
                    playerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={false} isFailed={false}/>);
                }
            });
        }

        if(this.props.winners.length > 0){
            this.props.winners.forEach((currentPlayer, i) => {
                if(!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    winnerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={true} isFailed={false}/>);
                }
            });
        }

        return (
            <>
                <div className={'table-responsive last-bets num-style mb-5'}>
                    <Table className={"mb-2 " + this.state.font}>
                        <thead>
                        <tr>
                            <th>
                                PLAYER
                            </th>
                            <th>
                                BET
                            </th>
                            <th className={'text-center'}>
                                PROFIT
                            </th>
                            <th className={'text-center'}>
                                NUMBERS
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {playerRows}
                        {winnerRows}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
}

class ShowUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var size = 50;
        var players = sortByAmount(this.props.players);
        var winners = sortByCashout(this.props.winners);
        players.length = Math.min(players.length, size);
        winners.length = Math.min(winners.length, size);
        return (
            <UsersTable
                engine={this.props.engine}
                players={players}
                winners={winners}
            />
        );
    }
}

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cc: null,
            game_status: null
        }
    }

    componentDidMount() {
        let { engine } = this.props;
        engine.on(C.KENO_AMOUNT, data => this.amount(data));
        engine.on(C.STATUS_KENO, data => this.gameSync(data));
    }

    gameSync(data) {
        this.setState({ game_status: data.status });
    }

    amount(data){
        let amount = data.amount;

        if(this.props.currentPlayer.numbers !== undefined)
        if(this.props.currentPlayer.numbers.includes(amount))
        {
            this.setState({ cc: 'success' })
        }
    }

    render(){
        if(this.props.currentPlayer.name === undefined)
            return null;
        
        let self = '', type = 'white text-warning', isWinner = false;

        let bet = this.props.currentPlayer.amount;

        let profit = this.props.currentPlayer.won !== undefined ? forceSatoshiFormat(this.props.currentPlayer.won) : '-';

        if(parseFloat(this.props.currentPlayer.uid) === parseFloat(userUID()))
            self = 'bg-soft-warning';

        if(this.props.isWinner){
            type = 'success';
        }

        if(this.state.game_status === 'busted_keno'){
            if(!this.props.isWinner)
                type = 'failed';
        }

        if(type ==='white text-secondary'){
            isWinner = true;
        }

        let cc = this.state.cc;

        let _this = this;

        function createMarkup(numbers) {
            let res = '';
            let dotted = '';

            //Currect Numbers
            let currect = (_this.props.currentPlayer.crash !== 'undefined') ? _this.props.currentPlayer.crash: false;

            if(numbers !== undefined)
                if(numbers.length > 3){
                    dotted = '...';
                }

            if(numbers !== undefined)
                numbers.length = 3;

            if(numbers !== undefined)
                numbers.forEach((number, i) => {

                    if(currect.includes(number))
                        cc = 'success';

                    res += "<div class=\"avatar-box thumb-xs align-self-center\">\n" +
                        "<span class=\"avatar-title bg-soft-info rounded-circle text-"+ cc +"\">"+number+"</span>\n" +
                        "</div>";
                });

            return {__html: res + dotted};
        }

        return (
            <tr key={this.props.currentPlayer.uid} className={type + ' ' + self + ' q-crash text-center'}>
                <td className="text-left">
                    <UserModal username={this.props.currentPlayer.name} id={this.props.currentPlayer.uid} queue={false} isWinner={profit} />
                </td>
                <td className="text-left" size="20">
                    <img src={'/assets/images/' + this.props.currentPlayer.coin + '.png'} alt="COIN" className={'mini-coin-57'}/>
                    {forceSatoshiFormat(bet)} <span className={'num-style hidden-sm'}>{__.upperCase(this.props.currentPlayer.coin)}</span>
                </td>
                <td>
                    <img src={'/assets/images/' + this.props.currentPlayer.coin + '.png'} alt="COIN" className={'mini-coin-57'}/>
                    {profit} <span className={'num-style hidden-sm'}>{__.upperCase(this.props.currentPlayer.coin)}</span> </td>
                <td dangerouslySetInnerHTML={createMarkup(this.props.currentPlayer.numbers)} />
            </tr>
        )

    }
}

function sortByAmount(input) {
    function r(c) {
        return c.amount ? - c.amount : null;
    }
    return __.sortBy(input, r);
}

function sortByCashout(input) {
    function r(c) {
        return c.current ? - c.current : null;
    }
    return __.sortBy(input, r);
}

class MyBets extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            loading: true,
            players: [],
            name: storage.getKey('name'),
            game: 'keno',
            gameCode: md5('keno')
        };
    }

    componentDidMount(){
        socket.emit(C.MY_BETS, encode({game: this.state.game}));
        socket.on(C.MY_BETS, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addList(decode(data)));
        this.state.players.length = 10;
    }

    addList(player){
        if(player.name === this.state.name){
            let row = <Players forMy={true} player={player} />;
            this.setState(state => ({ players: [row, ...state.players] }));


            if(this.state.players.length > 10)
                this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });

        }
    }

    makeList(arr){
        if(typeof arr.history === "undefined") return;
        this.setState({ loading: false });
        arr.history.forEach((player, i) => {
            let row = <Players key={i} forMy={true} player={player} />;
            this.setState(state => ({ players: [row, ...state.players] }));
        });
    }

    render () {
        return(
                <>
                    <div className={'table-responsive last-bets num-style mb-0'}>
                        {this.state.loading ?
                        <>
                            <div className="text-center">
                               <div className="spinner-grow text-white my-3" role="status"/>
                            </div>
                        </>
                        :
                        <Table className={"mb-0"}>
                            <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Profit
                                    </th>
                                    <th>
                                        Date/Time
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.players.length === 0 ?
                                <div className={'alert bg-soft-dark mt-2 font-13 text-yellow text-center'}>
                                    No Records
                                </div>
                                :
                                <>{this.state.players}</>
                            }
                            </tbody>
                        </Table>
                        }
                    </div>
                </>
        );
    }
}


class Players extends React.Component{
    constructor(props){
        super(props);
    }

    gameDetail = () => {
        Event.emit('single_game_modal', { data: this.props.player })
    }

    render() {
        let {name, uid, amount, profit, coin, created, hash, gid, direct, forMy} = this.props.player;
        let isWinner = false;
        let date = fixDate(created);

        if(direct){
            date = timeConvertor(created);
        }

        if(__.toNumber(profit) !== 0.00000000)
            isWinner = true;

        return(
            <tr className={'q-crash cpt'}>
                <td onClick={this.gameDetail}>
                    {gid}
                </td>
                {forMy === false &&
                    <td>
                        <UserModal id={uid} username={name} isWinner={isWinner} />
                    </td>
                }
                <td onClick={this.gameDetail} className={(isWinner===true) ? 'text-success-2 num-style': 'num-style'}>
                    <img src={'/assets/images/' + __.upperCase(coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {forceSatoshiFormat(amount)} {__.upperCase(coin)}
                </td>
                <td onClick={this.gameDetail} className={(isWinner===true) ? 'text-success-2 num-style': 'num-style'}>
                    <img src={'/assets/images/' + __.upperCase(coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {forceSatoshiFormat(profit)} {__.upperCase(coin)}
                </td>
                <td onClick={this.gameDetail}>
                    {date}
                </td>
            </tr>
        );
    }
}

export default Queue;
