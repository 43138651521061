import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setPlay } from "../../../actions/gamePlay";
import { setWallet } from "../../../actions/gameWallet";
import { setClassicDiceResult } from "../../../actions/gameResult";
import {
  Event,
  __,
  forceSatoshiFormat,
  isValidNumber,
  sendNotfication,
  wait,
  isMobile,
  playAudio,
} from "../../../Helper";
import C from "../../../Constant";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      started: false,
      bet: "manual",
      isLogged: storage.getKey("logged") !== null ? true : false,
      buttonText: "Roll",
      buttonType: "",
      amount: forceSatoshiFormat(
        storage.getKey("lam") ? storage.getKey("lam") : 0.0000001
      ),
      initialNumber: forceSatoshiFormat(
        storage.getKey("alam") ? storage.getKey("alam") : 0.0000001
      ),
      outError: false,
      inputDisabled: false,
      betNumber: 10,
      firstBetNumber: 10,
      onWin: 0,
      onLost: 0,
      isWin: false,
      stopOnWin: 0,
      type: "Under",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
  }

  componentDidMount() {
    if (this._isMounted) {
      let { engine } = this.state;

      wait(300).then(() => {
        if (isMobile()) {
          this.setState({ padding: "p-1" });
        }
      });

      this.props.gameCoin();
      engine.on(C.PLAY_CLASSIC_DICE, (data) => this.play(data));
      engine.on(C.BUSTED_CLASSIC_DICE, (data) => this.busted(data));
      engine.on(C.ERROR_CLASSIC_DICE, (data) => this.error(data));
      engine.on("stop_classic_dice", () => this.stopAuto());
      engine.on("stop_playing", () => this.stopManual());

      Event.on("change_type", (type) => {
        this.setState({ type: type });
      });
    }
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
  }

  stopManual() {
    if (this._isMounted) {
      this.setState({
        started: false,
        buttonText: "Place Bet",
        inputDisabled: false,
      });
    }
  }

  stopAuto() {
    if (this._isMounted) {
      this.setState({
        started: false,
        buttonText: "Start Auto Mode",
        inputDisabled: false,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({
        inputDisabled: false,
        started: false,
        buttonText: "Roll",
      });
    }
  }

  play(data) {
    if (this._isMounted) {
      this.props.setClassicDiceResult(parseFloat(data.target));
      this.props.setPlay(true);
      playAudio("dice2.mp3");
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { engine, bet, started } = this.state;
      this.setState({ inputDisabled: false });

      let isWinn = false;
      this.setState({ isWin: false });

      if (parseFloat(data.profit) !== 0) {
        isWinn = true;
        this.setState({ isWin: true });
      }

      if (bet === "auto") {
        if (started) {
          this.setState({ inputDisabled: true });
          // this.setState({ isWin: isWinn });
          engine.isWin = isWinn;
          engine.roundProfit =
            __.toNumber(data.profit) + __.toNumber(engine.roundProfit);
          this.placeAutoBet();
        }
      }

      if (isWinn) {
        playAudio("win.mp3");
        document.querySelector(
          ".dice-result span"
        ).style.background = `#35e179`;
        document.querySelector(".dice-result span").classList.add("add-sh");
        document.querySelector(".dice-result span").classList.remove("add-shg");
      } else {
        // playAudio('lost.mp3')
        document.querySelector(
          ".dice-result span"
        ).style.background = `#fd6638`;
        document.querySelector(".dice-result span").classList.add("add-shg");
        document.querySelector(".dice-result span").classList.remove("add-sh");
      }
    }
  }

  placeAutoBet() {
    if (this._isMounted) {
      let {
        engine,
        amount,
        betNumber,
        firstBetNumber,
        onWin,
        onLost,
        stopOnWin,
        isWin,
        initialNumber,
      } = this.state;

      wait(1000).then(() => {
        if (onWin != 0) {
          // It Mean Player has win in this round
          if (isWin) {
            var amountChange = amount * parseInt(onWin);
            this.setState({ amount: amountChange });
            console.log("onWin amount:", amountChange);
          }
        }

        if (onLost != 0) {
          // It Mean Player has lost in this round
          if (!isWin) {
            var amountChange = amount * parseInt(onLost);
            this.setState({ amount: amountChange });
            console.log("onLose amount:", amountChange);
          } else if (isWin) {
            console.log(initialNumber, "initial");
            this.setState({ amount: initialNumber });
          }
        }

        console.log(isWin, "iswin");

        console.log("onwin state auto", onWin);

        console.log(parseFloat(amount), "amount");

        engine.init = true;
        engine.amount = parseFloat(amount);
        engine.payout = this.props.classic_dice_payout;
        engine.chance = this.props.classic_dice_chance;
        engine.type = this.props.classic_dice_type;
        engine.coin = this.props.coin;
        engine.betNumber = firstBetNumber;
        engine.stopOnWin = parseInt(stopOnWin);
        engine.onWin = parseInt(onWin);
        engine.onLost = parseInt(onLost);
        engine.autoStart();
        this.setState({ betNumber: betNumber - 1 });
      });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount } = this.state;
      wait(500).then(() => {
        engine.init = true;
        engine.game = "classic_dice";
        engine.payout = this.props.classic_dice_payout;
        engine.chance = this.props.classic_dice_chance;
        engine.type = this.props.classic_dice_type;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  isCorrectBet(amount) {
    if (this._isMounted) {
      if (__.isUndefined(amount)) return;

      if (amount === "") return false;

      if (!isValidNumber(amount)) return false;

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.0000001) });
      } else {
        amount = Math.min(
          parseFloat(storage.getKey("credit")),
          parseFloat(amount)
        );
        this.setState({ amount: forceSatoshiFormat(amount) });
      }

      return true;
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { bet, started, isLogged, amount } = this.state;

      this.setState({ initialNumber: amount });
      storage.setKey("alam", amount);
      console.log(this.state.initialNumber, "initial");

      // Check User
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      // Check is Correct Bet
      if (!this.isCorrectBet(amount)) return false;

      this.setState({ inputDisabled: true });

      if (bet === "auto") {
        if (started) {
          this.setState({
            started: false,
            buttonText: "Start Auto Mode",
            inputDisabled: false,
          });
          return false;
        } else {
          this.setState({ started: true, buttonText: "Stop Auto Mode" });
          this.placeAutoBet();
        }
      } else {
        this.placeBet();
      }
    }
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.type === "checkbox" ? true : target.value;
      let name = target.name;

      if (name === "amount" || name === "stopOnWin") {
        if (!isValidNumber(value)) return;
      }

      if (name === "amount") {
        storage.setKey("lam", value);
      }

      this.setState({ [name]: value });
    }
  }

  betMode = (type) => {
    if (this._isMounted) {
      let text;

      if (type === "auto") {
        text = "Start Auto Mode";
      } else {
        text = "Roll";
      }
      this.setState({ bet: type, buttonText: text });
    }
  };

  setAmount = (val) => {
    var am = forceSatoshiFormat(val);
    this.setState({ amount: am });
    // if (this._isMounted) {
    //   e.preventDefault();
    //   this.setState({ initialNUmber: am });
    // }

    storage.setKey("lam", am);
  };

  setNumber = (e, num) => {
    if (this._isMounted) {
      e.preventDefault();
      this.setState({ betNumber: num });
      this.setState({ firstBetNumber: num });
    }
  };

  render() {
    let { bet, inputDisabled, started, padding } = this.state;

    const withValueLimit = ({ floatValue }) => floatValue <= 1400;

    return (
      <>
        {this._isMounted && (
          <>
            <form
              className="w-100 mt-1"
              onSubmit={(e) => {
                this.handleBet(e);
              }}
            >
              <Row>
                <Col sm={12} className={"d-md-none d-sm-block"}>
                  {bet === "manual" && (
                    <Button
                      variant={
                        "btn btn-xs btn-block btn-bet py-2 mb-2 " +
                        this.state.buttonType
                      }
                      disabled={inputDisabled}
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  )}
                </Col>
                <Col xl={6} md={7}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                          />
                          BET
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled={inputDisabled}
                        className="form-control"
                        id="amount"
                        name="amount"
                        value={this.state.amount}
                        autoComplete={"off"}
                        onKeyUp={this.handleInputChange}
                        onChange={this.handleInputChange}
                      />
                      <div className="input-group-append">
                        <RangeCredit set={this.setAmount} />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={5}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                          />
                          WIN AMOUNT
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={forceSatoshiFormat(
                          this.props.classic_dice_payout
                            ? this.state.amount * this.props.classic_dice_payout
                            : 0.00000004
                        )}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {bet === "manual" && (
                <Row>
                  <Col md={5} sm={12} className="m-auto hidden-sm">
                    <div className={"form-group rev mb-1"}>
                      <Button
                        variant={
                          "btn btn-md btn-block mt-2 btn-bet " +
                          this.state.buttonType
                        }
                        disabled={inputDisabled}
                        type="submit"
                      >
                        {this.state.buttonText}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {bet === "auto" && (
                <>
                  <Row>
                    <Col xl={6} md={6} sm={6}>
                      <div className={"form-group mb-1 bet-input"}>
                        <div className="input-group">
                          <div className="input-group-append">
                            <span className="input-group-text">Bets Num</span>
                          </div>
                          <input
                            type="number"
                            disabled={inputDisabled}
                            className="form-control"
                            required={true}
                            value={this.state.betNumber}
                            onChange={(e) => this.setNumber(e, e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              onClick={(e) => this.setNumber(e, 10)}
                              className={"btn btn-s-4 btn-xs"}
                            >
                              10
                            </button>
                            <button
                              onClick={(e) => this.setNumber(e, 50)}
                              className={"btn btn-s-4 btn-xs"}
                            >
                              50
                            </button>
                            <button
                              onClick={(e) => this.setNumber(e, 100)}
                              className={"btn btn-s-4 btn-xs"}
                            >
                              100
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} md={6} sm={12}>
                      <div className={"form-group mb-1 bet-input"}>
                        <div className="input-group">
                          <div className="input-group-append">
                            <span className="input-group-text">
                              Increase Amount On Win
                            </span>
                          </div>
                          <input
                            disabled={inputDisabled}
                            type="number"
                            className="form-control"
                            name="onWin"
                            min="0"
                            autoComplete={"off"}
                            value={this.state.onWin}
                            onChange={(e) =>
                              this.setState({ onWin: e.target.value })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn bg-cs2 after-text no-hover npt nh"
                              type="button"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} md={6} sm={12}>
                      <div className={"form-group mb-1 bet-input"}>
                        <div className="input-group">
                          <div className="input-group-append">
                            <span className="input-group-text">
                              Increase Amount On Lost
                            </span>
                          </div>
                          <input
                            disabled={inputDisabled}
                            type="number"
                            className="form-control"
                            name="onLost"
                            min="0"
                            autoComplete={"off"}
                            value={this.state.onLost}
                            onChange={(e) =>
                              this.setState({ onLost: e.target.value })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn bg-cs2 after-text no-hover npt nh"
                              type="button"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} md={6} sm={12}>
                      <div className={"form-group mb-1 bet-input"}>
                        <div className="input-group">
                          <div className="input-group-append">
                            <span className="input-group-text">
                              Stop On Win
                            </span>
                          </div>
                          <input
                            disabled={inputDisabled}
                            type="text"
                            className="form-control"
                            name="stopOnWin"
                            placeholder=".."
                            value={this.state.stopOnWin}
                            autoComplete={"off"}
                            onChange={this.handleInputChange}
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <img
                                src={
                                  "/assets/images/" + this.props.coin + ".png"
                                }
                                className={"mini-coin-2 mr-1"}
                                alt=""
                              />
                              {this.props.coin}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} className={"m-auto"}>
                      <Button
                        variant={"btn btn-md btn-block mt-2 btn-bet"}
                        type="submit"
                      >
                        {this.state.buttonText}
                        {started && (
                          <>
                            <div
                              className={
                                "ml-2 spinner-border spinner-border-sm"
                              }
                            />
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </form>
            <div
              className="btn-group btn-group-toggle my-2 ml-1"
              data-toggle="buttons"
            >
              <label
                className={
                  "" + bet === "manual"
                    ? "btn bg-cs2 btn-xs active"
                    : "btn bg-cs2 btn-xs "
                }
              >
                <input
                  disabled={started}
                  onClick={(e) => this.betMode("manual")}
                  type="radio"
                />{" "}
                Manual
              </label>
              <label
                className={
                  "" + bet === "auto"
                    ? "btn bg-cs2 btn-xs active"
                    : "btn bg-cs2 btn-xs "
                }
              >
                <input
                  disabled={started}
                  onClick={(e) => this.betMode("auto")}
                  type="radio"
                />{" "}
                Auto
              </label>
            </div>
          </>
        )}
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
  classic_dice_payout: PropTypes.number,
  classic_dice_chance: PropTypes.number,
  classic_dice_type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
  classic_dice_payout: state.items.classic_dice_payout,
  classic_dice_chance: state.items.classic_dice_chance,
  classic_dice_type: state.items.classic_dice_type,
});

export default connect(mapStateToProps, {
  setPlay,
  gameCoin,
  setClassicDiceResult,
  setWallet,
})(Bet);
