import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown, Modal, Row, Col } from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import { __, wait, decode, encode, forceSatoshiFormat, Event } from "../../../../Helper";
import C from "../../../../Constant";

class Credit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showManage: false,
            token: storage.getKey('token'),
            coin: storage.getKey('coin') ? storage.getKey('coin') : "BTC",
            activeClientCoins: storage.getKey('active_coins') ? storage.getKey('active_coins') : null,
            selectedCoin: storage.getKey('coin'),
            credits: {},
            coins: [],
            manage_coins: [],
            different: [],
            credit: false,
            usd: storage.getKey('usd') === "true" ? true : false
        };
        this.selectCoin = this.selectCoin.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        //Get Redux Coin
        this.props.gameCoin();

        Event.on('other_coins', () => {
            this.setState({ showManage: true });
        })

        if (this.state.token !== null)
            socket.emit(C.CREDIT, encode({ token: this.state.token, coin: this.state.coin }));

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));

        if (this.state.activeClientCoins === null) {
            var arr = [];
            for (var i = 1; i < 8; i++) {
                arr.push(i)
            }
            storage.setKey('active_coins', arr);
        }

        this.setUpManageCoins();

        if (this.state.showManage) {
            wait(1000).then(() => {
                const ps = new PerfectScrollbar('.ccoins', {
                    wheelSpeed: 1,
                    suppressScrollX: true,
                    wheelPropagation: true,
                    minScrollbarLength: 2
                });
                ps.update();
            })
        }

        window.addEventListener('wheel', this.handleScroll, { passive: false });
        window.addEventListener('mousedown', this.handleClickOutside);
    }

    handleScroll(e) {
        function sicimsc(e) {
            e = e || window.event;
            if (e.preventDefault)
                e.preventDefault();
            e.returnValue = false;
        }
        let creditBody = document.querySelector('.prevscrol');
        if (creditBody) {
            creditBody.scrollTop -= e.wheelDeltaY;
            sicimsc(e);
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ show: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.handleScroll, { passive: false });
        window.removeEventListener('mousedown', this.handleClickOutside);
    }

    activeCoinChange = (id, active) => {
        if (id === 1) return;
        const activeClientCoins = storage.getKey('active_coins')
        let update;

        if (!active) {
            update = activeClientCoins + ',' + id;
        }
        else {
            update = __.replace(activeClientCoins, "," + id, "");
        }

        update = __.replace(update, null, "");

        const compareNumbers = (a, b) => a - b;

        let arr = [];

        const regex = /(\d+)/gi;
        let m;

        while ((m = regex.exec(update)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            m.forEach((match, groupIndex) => {
                match = parseFloat(match);
                arr.push(match);
            });
        }

        arr.sort(compareNumbers);
        var c = __.xor(arr);

        storage.setKey('active_coins', c);

        this.setUpManageCoins();
    }

    setUpManageCoins = () => {
        const { t } = this.props;
        let self = this;

        this.setState({ manage_coins: [] });

        const activeCoins = storage.getKey('active_coins');
        let ac = __.split(activeCoins, ",");

        coins.forEach((item, key) => {

            //Default
            if (activeCoins === null) {
                let add = <Dropdown.Item key={key} className={"num-style"}>
                    <div className="float-left text-white ncpt">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-12'} />
                        {item.preffix}
                    </div>
                    <div className="float-right">
                        <div className="custom-control custom-switch switch-success">
                            <input type="checkbox" className="custom-control-input" id={"switch_" + key} checked={true} />
                            <label onClick={(e) => self.activeCoinChange(parseFloat(item.id), true)}
                                className={'custom-control-label'} htmlFor={"switch_" + key}>{item.name}</label>
                        </div>
                    </div>
                </Dropdown.Item>;
                return self.setState(state => ({ manage_coins: [add, ...state.manage_coins] }));
            }

            let active = false;

            let check = ac.some(element => parseFloat(element) === parseFloat(item.id))

            if (check) {
                active = true;
            }

            var disabled = false;

            if (item.preffix === 'BTC')
                disabled = true;

            let add = <Dropdown.Item key={key} className={"num-style"}>
                <div className="float-left text-white ncpt">
                    <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-12'} />
                    {item.preffix}
                </div>
                <div className="float-right">
                    <div className="custom-control custom-switch switch-success">
                        <input type="checkbox" className="custom-control-input" id={"switch_" + key} checked={active} disabled={disabled} />
                        <label onClick={(e) => self.activeCoinChange(parseFloat(item.id), active)}
                            className="custom-control-label" htmlFor={"switch_" + key}>{item.name}</label>
                    </div>
                </div>
            </Dropdown.Item>;
            self.setState(state => ({ manage_coins: [add, ...state.manage_coins] }));
        });
    }

    updateAllCoins(newCoin = null, newVal = null) {
        const activeClientCoins = storage.getKey('active_coins');
        this.setState({ coins: [] });

        let ac = null, style, value, coin;

        if (activeClientCoins !== null) {
            ac = activeClientCoins.split(",");

            __.reverse(ac).forEach((num, key) => {
                var item = __.find(coins, { 'id': parseFloat(num) });
                if (__.isUndefined(item)) return;
                style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';

                if (newVal !== null)
                    value = newVal;
                else
                    value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);

                if (__.lowerCase(item.preffix) === newCoin) {
                    this.state.credits[newCoin] = value
                }

                if (newCoin !== null) {
                    coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix, newCoin, newVal)} key={key} className={"num-style " + style}>
                        <div className="float-left">
                            <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                            {item.preffix}
                        </div>
                        {__.lowerCase(item.preffix) === newCoin ?
                            <div className="float-right">{value}</div>
                            :
                            <div className="float-right">{forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)])}</div>
                        }
                    </Dropdown.Item>;
                }
                else {
                    coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                        <div className="float-left">
                            <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                            {item.preffix}
                        </div>
                        <div className="float-right">{value}</div>
                    </Dropdown.Item>;
                }

                this.setState(state => ({ coins: [coin, ...state.coins] }));
            });
        }
        else {
            coins.forEach((item, key) => {

                if (ac !== null) {
                    let check = ac.some(element => parseFloat(element) === parseFloat(item.id))

                    if (check) {
                        style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                        value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                        coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                            <div className="float-left">
                                <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                                {item.preffix}
                            </div>
                            <div className="float-right">{value}</div>
                        </Dropdown.Item>;
                    }
                }
                else {
                    style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                    value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                    coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                        <div className="float-left">
                            <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                            {item.preffix}
                        </div>
                        <div className="float-right">{value}</div>
                    </Dropdown.Item>;
                }
                this.setState(state => ({ coins: [coin, ...state.coins] }));
            });
        }

    }

    getCredit(data) {
        let { credit } = data;

        this.setState({ credits: credit });

        let currentCoin = __.lowerCase(this.props.coin);
        let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
        this.setState({ credit: currentCredit });
        storage.setKey('credit', currentCredit);

        //Set for Redux
        this.props.setCredit(currentCredit);
        this.updateAllCoins();
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success'
            }
        }
        else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger'
            }
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState(state => ({ different: [arr, ...state.different] }));
        }

        var realCredit = credit
        credit = forceSatoshiFormat(credit);

        this.setState({ credit: credit });
        storage.setKey('credit', credit);

        this.updateParentCoin(__.lowerCase(coin), credit);
        this.updateAllCoins(__.lowerCase(coin), realCredit);

        //Set for Redux
        this.props.setCredit(credit);
    }

    updateParentCoin(coin, amount) {
        this.setState({ coins: [] });

        coins.forEach((item, key) => {
            if (__.lowerCase(item.preffix) === coin) {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let update = <Dropdown.Item onClick={e => this.selectCoin(__.upperCase(coin))} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                        {item.preffix}
                    </div>
                    <div className="float-right">{forceSatoshiFormat(amount)}</div>
                </Dropdown.Item>;
                this.setState(state => ({ coins: [update, ...state.coins] }));
            }
            else {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                let update = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'} />
                        {item.preffix}
                    </div>
                    <div className="float-right">{value}</div>
                </Dropdown.Item>;
                this.setState(state => ({ coins: [update, ...state.coins] }));
            }
        });
    }

    selectCoin(name, newCoin, newVal) {
        //Fix For Double selection
        if (storage.getKey('coin') === name) return;
        storage.setKey('coin', name);

        let credit = this.state.credits[__.lowerCase(name)];
        this.setState({ coin: name, credit: credit, selectedCoin: name });

        //Set Coin For Redux
        this.props.setCoin(name);
        this.props.setCredit(credit);

        wait(200).then(() => {
            this.updateAllCoins(newCoin, newVal);
            Event.emit('coin_changed', name);
        })
    }

    addDiff(data, i) {
        let id = 'id_' + Math.floor(Math.random() * 1000 + 1);

        wait(2000).then(() => {
            try {
                document.getElementById(id).classList.remove('frd');
                document.getElementById(id).classList.add('fadeOutDown');
            }
            catch (e) { }
            this.state.different.splice(i, 1);
        });

        return <li key={i} id={id} className={'list-inline w-100 text-right animated frd ' + data.color}> {(data.color === 'text-danger' ? '-' : '+')}
            {forceSatoshiFormat(data.amount, this.state.coin)}
        </li>;
    }

    closeManage = () => {
        this.setState({ showManage: false });
        wait(200).then(() => {
            this.updateAllCoins();
        })
    }

    manageCoins = () => {
        this.setState({ showManage: true });
    }

    open = (e) => {
        this.setState({ show: !this.state.show });
    }

    usdformat = () => {
        this.setState({ usd: !this.state.usd });
        wait(1000).then(() => {
            storage.setKey('usd', this.state.usd)
        })
    }

    render() {
        let { credit, different, coin, coins, show } = this.state;
        credit = forceSatoshiFormat(credit, coin, this.state.usd); // NEED TO REMOVE FOR FIX
        let diff = different.map((data, i) =>
            this.addDiff(data, i)
        );

        const style = show ? 'show prevscrol' : 'd-nones';
        const { t } = this.props;
        return (
            <>
                <li>
                    <div className="crypto-balance" ref={this.wrapperRef}>
                        <ul id="cup2" className={'mt-2 mr-1'}>{diff}</ul>
                        <div className="btc-balance">
                            <div className={"clist mt-0 text-right float-right cpt"} onClick={e => this.open(e)}>
                                <div variant="block" className={'p-0 mt-0'} id="dropdown-split-coins">
                                    <h5 className="m-0">
                                        <span className={'text-white'}>
                                            <img src={'/assets/images/' + __.lowerCase(coin) + '.png'} className={'mini-coin-9 mr-2'} alt="" />
                                            {coin}
                                            <i className={'mdi mdi-chevron-down font-15'} />
                                        </span>
                                    </h5>
                                    <span className="text-white d-block font-14">
                                        {credit}
                                    </span>
                                </div>
                                <div className={"dopdown-menu-right num-style coins-list clist " + style} >
                                    {coins}
                                    <Row>
                                        <Col className="col-6">
                                            <button onClick={this.manageCoins} className="btn btn-xs bg-cs no-shadow btn-block mt-1">
                                                <i className="mdi mdi-coins align-middle mr-1" />
                                                {this.props.t('manage_coins')}
                                            </button>
                                        </Col>
                                        <Col className="col-6 ycenter">
                                            <div class="custom-control custom-switch switch-info mt-2 text-center">
                                                <input type="checkbox" class="custom-control-input" id="usdSwitch" checked={this.state.usd} />
                                                <label onClick={(e) => this.usdformat(e)} class="custom-control-label" for="usdSwitch">USD Format</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <Modal
                    size="sm"
                    centered={true}
                    backdrop={'static'}
                    show={this.state.showManage}
                    onHide={this.closeManage}
                    aria-labelledby="coins-modal"
                    className={"animated zoomIn"}
                >
                    <Modal.Header className={'font-light'}>
                        Manage Coins
                        <button type="button" className="close p-2" onClick={this.closeManage}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className="ccoins p-1">
                        {this.state.manage_coins}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

Credit.propTypes = {
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(Credit);